import { useMemo } from 'react'
import useActiveTemplate from './useActiveTemplate'
import { TemplateDetail, TemplateInputWidget } from '@/types'

const useActiveTemplateInputs = () => {
  const { data: activeTemplate, ...others } = useActiveTemplate()
  const inputs: TemplateInputWidget[] = useMemo(
    () =>
      ((activeTemplate as TemplateDetail)?.inputs ?? []).filter((item) => {
        // TODO: temp solution
        if (activeTemplate?.name === 'Logo Animation') {
          return item.key === 'input_image_url'
        }
        return item.visible !== false
      }),
    [activeTemplate],
  )

  return {
    ...others,
    data: inputs,
  }
}

export default useActiveTemplateInputs
