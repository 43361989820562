import { getTemplates } from '@/service/template.service'
import useService, { useCachedService } from '@banyudu/use-service'
import useInfinite from './useInfinite'
import { demoTemplates } from '@/mocks/data/template'

const validator = (params: Parameters<typeof getTemplates>[0]) => Boolean(params?.category)
const useTemplates = useService(getTemplates, validator)
export const useCachedTemplates = useCachedService(getTemplates, validator)

const getAllTemplates = async () => {
  const res = await getTemplates({ limit: 999999 })
  res.records = [...res.records, ...demoTemplates]
  return res
}

export const useCachedAllTemplates = useCachedService(getAllTemplates)

export const useInfiniteTemplates = useInfinite(useTemplates, {
  rowKey: 'template_id',
})
