import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import Link from '@/components/link'
import Checkbox from '@/components/checkbox'
import {
  showCreationsAtom,
  showModeListAtom,
  creationInputAtom,
  subscriptionDialogContentAtom,
  activeTemplateIdAtom,
} from '@/atoms'
import useAmplitude from '@/hooks/useAmplitude'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { cls, fullTextSearch, stopPropagation, whisper } from '@/utils'
import BadgeNew from '@/components/badges/new'
import useActivePlan from '@/hooks/useActivePlan'
import BadgeUpdate from '@/components/badges/update'
import useCreationModes from '@/hooks/useCreationModes'
import { useCachedTemplateCategories } from '@/hooks/useTemplateCategories'
import TemplateIcon from './icon'
import ChevronDown from '@haiper/icons-svg/icons/outline/chevron-down-small.svg'
import ChevronTop from '@haiper/icons-svg/icons/outline/chevron-top-small.svg'
import Button from '@/components/button'
import { useCachedAllTemplates } from '@/hooks/useTemplates'
import Input from '@/components/input'
import { uniq } from 'lodash-es'
import Empty from '@/components/empty'

interface CreationModeListProps {
  className?: string
  listClassName?: string
  inPopover?: boolean
  inDialog?: boolean
  onSelectMode?: () => void
}

export function CreationModeList({
  className,
  listClassName,
  onSelectMode,
  inPopover,
  inDialog,
}: CreationModeListProps) {
  const showCreations = useAtomValue(showCreationsAtom)
  const setShowModeList = useSetAtom(showModeListAtom)
  const [{ mode }, setCreationInput] = useAtom(creationInputAtom)
  const { track } = useAmplitude()
  const { data: activePlan, loading: activePlanLoading } = useActivePlan()
  const isFreePlan = (activePlan?.is_free || !activePlan) && !activePlanLoading
  const setSubscriptionDialogContent = useSetAtom(subscriptionDialogContentAtom)
  const { data: creationModeOptions } = useCreationModes()
  const { data: templateCategories } = useCachedTemplateCategories()
  const { data: allTemplatesRes } = useCachedAllTemplates()

  const [activeTemplateId, setActiveTemplateId] = useAtom(activeTemplateIdAtom)

  const allTemplates = useMemo(() => {
    return allTemplatesRes?.records ?? []
  }, [allTemplatesRes])

  const handleModeChange = useCallback(
    (option: (typeof creationModeOptions)[number]) => {
      if (isFreePlan && option.membersOnly) {
        setSubscriptionDialogContent({
          message: `Upgrade to Haiper Membership to access the ${option.name} feature.`,
        })
        return
      }

      const value = option.mode

      if (showCreations) {
        setShowModeList(false)
      }
      setCreationInput((prev) => ({
        ...prev,
        creation: undefined,
        mode: value,
        expanded: true,
        focusing: true,
      }))
      setActiveTemplateId(null)
      onSelectMode?.()
      track('input:creation:change-mode', {
        mode: value,
      })
    },
    [
      onSelectMode,
      setCreationInput,
      setShowModeList,
      showCreations,
      track,
      isFreePlan,
      setSubscriptionDialogContent,
      setActiveTemplateId,
    ],
  )

  const [keyword, setKeyword] = useState('')
  const [expandedCategories, setExpandedCategories] = useState<string[]>([])
  const [expandAllCategories, setExpandAllCategories] = useState(false)

  const toggleExpandedCategory = useCallback((category: string) => {
    setExpandAllCategories(false)
    setExpandedCategories((prev) => {
      if (prev.includes(category)) {
        return prev.filter((c) => c !== category)
      }
      return [...prev, category]
    })
  }, [])

  const selectTemplateById = useCallback(
    (templateId: string) => {
      const template = allTemplates.find((template) => template.template_id === templateId)
      setCreationInput((prev) => ({
        ...prev,
        creation: undefined,
        mode: undefined,
        expanded: true,
        focusing: true,
      }))
      setActiveTemplateId(template?.template_id ?? null)
      onSelectMode?.()
    },
    [allTemplates, setActiveTemplateId, onSelectMode, setCreationInput],
  )

  const filteredCreationModes = useMemo(() => {
    return (
      creationModeOptions
        ?.filter((e) => !e.hidden)
        .filter((e) => fullTextSearch(e.name, keyword) || fullTextSearch(e.mode, keyword)) ?? []
    )
  }, [creationModeOptions, keyword])

  const filteredTemplates = useMemo(() => {
    return (
      allTemplates?.filter(
        (template) => fullTextSearch(template.name, keyword) || fullTextSearch(template.introduction, keyword),
      ) ?? []
    )
  }, [allTemplates, keyword])

  const filteredTemplateCategories = useMemo(() => {
    const categoryNames = uniq(filteredTemplates.map((template) => template.category))
    return templateCategories?.filter((category) => categoryNames.includes(category.category)) ?? []
  }, [filteredTemplates, templateCategories])

  const showCreationModes = filteredCreationModes.length > 0
  const showTemplates = filteredTemplateCategories.length > 0
  const showEmpty = !showCreationModes && !showTemplates

  useEffect(() => {
    setExpandedCategories(filteredTemplateCategories?.length > 0 ? [filteredTemplateCategories[0]?.category] : [])
  }, [filteredTemplateCategories])

  useEffect(() => {
    setExpandAllCategories(!!keyword.trim())
  }, [keyword])

  return (
    <article
      className={cls(
        'max-w-[308px] xs:max-w-[636px] md:max-w-[960px] mx-auto flex flex-col justify-start w-full items-center',
        className,
      )}
      data-component='creation-input'
      data-outside='false'
    >
      {inPopover || inDialog ? (
        <span className='text-heading-lg font-bold tracking-32 w-full mb-2 text-center'>Creation Mode</span>
      ) : (
        <header className='md:flex flex-col justify-center items-center mb-8'>
          <h1 className='text-center mb-0 sm:text-heading-4xl text-heading-2xl text-text font-bold font-sn'>
            Choose a template to create your own video
          </h1>
        </header>
      )}
      <section
        className={cls(
          'max-w-[308px] xs:max-w-[636px] md:max-w-full px-8',
          inPopover ? 'max-w-full px-0' : '',
          listClassName,
        )}
        aria-label='content'
      >
        <div className='pt-6' aria-label='search creation mode'>
          <Input
            className='text-text w-full'
            placeholder='Search for Templates'
            defaultValue={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
        </div>
        <div
          className={cls('py-4 flex flex-col gap-4', showCreationModes ? 'flex' : 'hidden')}
          aria-label='basic ai tools'
        >
          <span className='text-body-lg font-medium tracking-32'>Basic AI Tools</span>
          <div
            className={cls(
              'grid grid-cols-1 gap-4 md:gap-6 w-full',
              inPopover ? 'gap-2 md:gap-2' : 'xs:grid-cols-2 md:grid-cols-3',
            )}
            aria-label='Creation Mode'
          >
            {creationModeOptions
              .filter((e) => !e.hidden)
              .filter((e) => fullTextSearch(e.name, keyword) || fullTextSearch(e.mode, keyword))
              .map((option) => {
                const { Icon, iconClassName } = option
                const needUpgrade = option.membersOnly && isFreePlan
                const isActive = mode === option.mode

                return (
                  <div
                    key={option.mode}
                    className={cls(
                      'h-18 relative cursor-pointer pl-4 flex bg-white/5 rounded-xl items-center border border-b-2 border-solid border-border box-border hover:border-border-hover active:border-border-hover',
                      'max-w-[308px] hover:bg-surface-hover hover:border-border-hover',
                      isActive ? 'cursor-default border-border-active hover:border-border-active bg-surface' : '',
                      !option.available
                        ? 'hover:bg-white/5 active:bg-white/5 shadow-none hover:border-border cursor-not-allowed'
                        : '',
                    )}
                    aria-label='creation mode item'
                    onClick={(e: any) => {
                      e?.preventDefault?.()
                      e?.stopPropagation?.()
                      if (option.available) {
                        handleModeChange(option)
                      }
                    }}
                  >
                    <div
                      className={cls(
                        'opacity-0 size-0 absolute',
                        option.available ? 'pointer-events-auto' : 'pointer-events-none',
                      )}
                    />
                    <div className='shrink-0 flex flex-row items-center w-full'>
                      <div
                        className={cls(
                          'size-10 p-2 rounded-full shrink-0 mr-3 text-icon-on-color',
                          needUpgrade ? 'opacity-50' : '',
                          iconClassName,
                        )}
                      >
                        <Icon className={cls(!option.available ? 'opacity-50' : '')} alt={option.name} />
                      </div>
                      <div>
                        <div className={cls('truncate', needUpgrade ? 'text-text-disabled' : '')}>{option.name}</div>
                        {option.newFeature ? (
                          <div className='flex items-center text-body-sm text-text-interactive'>
                            {option.newFeature}
                          </div>
                        ) : null}
                        {option.available ? null : (
                          <div className='text-text-subdued text-body-md ml-auto'>Coming soon</div>
                        )}
                      </div>
                      {option.membersOnly ? (
                        <div className='flex items-center gap-1 text-body-sm px-1 ml-auto mr-4'>
                          <Link
                            href='/membership'
                            className='leading-5 tracking-15 font-bold text-text-interactive'
                            onClick={stopPropagation as any}
                          >
                            Members
                          </Link>
                          <span className=''>only</span>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className={cls(
                        'absolute right-2 inset-y-2 flex flex-col items-end gap-0.5',
                        option.isNew || option.isUpdated ? 'justify-start' : 'justify-center',
                      )}
                    >
                      {option.isNew ? <BadgeNew className='' /> : null}
                      {option.isUpdated ? <BadgeUpdate className='' /> : null}
                      {isActive ? <Checkbox checked className='mr-2' /> : null}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
        <div className={cls('pt-4 flex flex-col gap-4', showTemplates ? 'flex' : 'hidden')} aria-label='templates'>
          <span className='text-body-lg font-medium tracking-32'>Templates</span>
          <div className='flex flex-col w-full'>
            {templateCategories?.map((category) => {
              const expanded = expandedCategories.includes(category.category) || expandAllCategories
              const ExpandIcon = expanded ? ChevronTop : ChevronDown
              const toggle = () => toggleExpandedCategory(category.category)
              const templatesInCategory = allTemplates
                ?.filter((template) => template.category === category.category)
                ?.filter(
                  (template) =>
                    fullTextSearch(template.name, keyword) || fullTextSearch(template.introduction, keyword),
                )

              if (!templatesInCategory?.length) {
                return null
              }
              return (
                <div key={category.category} className='border-t border-border flex flex-col'>
                  <div
                    className='flex items-center gap-1 py-3 cursor-pointer text-text bg-surface rounded-md'
                    aria-label='info'
                    onClick={toggle}
                  >
                    <TemplateIcon src={category.icon} size='sm' />
                    <span className='text-body-md font-medium tracking-15 flex-1 text-left px-1'>{category.label}</span>
                    <Button variant='transparent' className='size-8 md:size-8 grow-0 shrink-0 p-0 bg-transparent'>
                      <ExpandIcon className='text-icon-subdued' />
                    </Button>
                  </div>
                  <div
                    className={cls('pb-4 grid grid-cols-2 md:grid-cols-3 gap-3', expanded ? '' : 'hidden')}
                    aria-label='templates'
                  >
                    {templatesInCategory.map((template) => {
                      const isActiveTemplate = Boolean(activeTemplateId && activeTemplateId === template.template_id)
                      return (
                        <div
                          key={template.template_id}
                          className={cls(
                            'flex p-3 pr-4 items-center justify-between rounded-lg border border-b-2 h-14',
                            isActiveTemplate ? 'border-border-active' : 'cursor-pointer hover:bg-surface-hover',
                          )}
                          onClick={() => selectTemplateById(template.template_id)}
                        >
                          <span className='flex-1 text-body-md font-medium tracking-15'>{template.name}</span>
                          <Checkbox
                            checked={isActiveTemplate}
                            className={cls('', isActiveTemplate ? 'visible' : 'invisible')}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {showEmpty && <Empty className='w-full py-10' />}
      </section>
    </article>
  )
}
