import Button from '@/components/button'
import { cls } from '@/utils'
import ChevronDown from '@haiper/icons-svg/icons/outline/chevron-down-small.svg'

export interface ModelVersionPickerProps {
  className?: string
}

export default function ModelVersionPicker({
  className,
}: ModelVersionPickerProps) {
  return (
    <Button
      aria-label='model version'
      type='button'
      variant='outline'
      className={cls(
        'flex items-center justify-center h-10 p-2 gap-3 text-body-md cursor-pointer select-none text-text rounded-[48px] border-2 border-solid border-border transition-all duration-200 ease-in-out hover:border-border-hover active:border-border-hover bg-surface hover:bg-surface-hover active:bg-surface-hover hover:shadow-[0_0_10px_0_rgba(250,250,250,0.2)] active:shadow-[0_0_10px_0_rgba(250,250,250,0.2)]',
        'items-center relative hidden',
        className,
      )}
      data-testid='creation-mode-button'
    >
      <div className='flex items-center gap-2'>
        <span
          aria-label='modeName'
          // className='text-heading-lg font-bold hidden md:block'
          className='text-body-md'
        >
          Haiper 1.5
        </span>
        <ChevronDown className='size-6 text-icon' />
      </div>
    </Button>
  )
}
