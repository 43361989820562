import { creationInputAtom, enableAfcAtom } from '@/atoms'
import { CreationModeEnum, TemplateDetail, TemplateInputWidget } from '@/types'
import { useAtom, useAtomValue } from 'jotai'
import { useMemo } from 'react'
import useActiveTemplate from './useActiveTemplate'

const useCreationSettingVisibility = () => {
  const [{ mode }] = useAtom(creationInputAtom)
  const { data: activeTemplate } = useActiveTemplate()

  const templateInputs: TemplateInputWidget[] = useMemo(() => {
    return (activeTemplate as TemplateDetail)?.inputs ?? []
  }, [activeTemplate])

  const showMotionLevel =
    [CreationModeEnum.Create, CreationModeEnum.Animate].includes(mode as any) ||
    templateInputs.some((input) => input.key === 'motion_level')

  const showDuration =
    [CreationModeEnum.Create, CreationModeEnum.Animate, CreationModeEnum.CreateHD, CreationModeEnum.AnimateHD].includes(
      mode as any,
    ) ||
    // templateInputs.some((input) => input.key === 'duration')
    // TODO: temp solution
    (!!activeTemplate && !(activeTemplate as TemplateDetail)?.inputs?.some((input) => input.widget === 'video_input'))

  const showResolution =
    [
      CreationModeEnum.CreateImg,
      CreationModeEnum.Create,
      CreationModeEnum.Animate,
      CreationModeEnum.CreateHD,
      CreationModeEnum.AnimateHD,
    ].includes(mode as any) || templateInputs.some((input) => input.key === 'resolution')

  const showAspectRatio =
    [CreationModeEnum.CreateHD, CreationModeEnum.CreateImg].includes(mode as any) ||
    templateInputs.some((input) => input.key === 'aspect_ratio')

  const showStyles =
    [CreationModeEnum.Create, CreationModeEnum.CreateHD, CreationModeEnum.CreateImg].includes(mode as any) ||
    !!activeTemplate?.styles?.length

  const enableAfc = useAtomValue(enableAfcAtom)

  const showCameraMovement =
    [CreationModeEnum.CreateHD, CreationModeEnum.Extend].includes(mode as any) ||
    (CreationModeEnum.AnimateHD === mode && !enableAfc)

  const showSeed = true

  const validKeys: string[] = useMemo(() => {
    return [
      showMotionLevel && 'motion_level',
      showDuration && 'duration',
      showResolution && 'resolution',
      showAspectRatio && 'aspect_ratio',
      showSeed && 'seed',
    ].filter(Boolean) as string[]
  }, [showMotionLevel, showDuration, showResolution, showAspectRatio, showSeed])

  return {
    showSeed,
    showMotionLevel,
    showDuration,
    showResolution,
    showAspectRatio,
    showCameraMovement,
    showStyles,
    validKeys,
  }
}

export default useCreationSettingVisibility
