import { CreationInputControlsProps } from '../common'
import { cls } from '@/utils'
import { useMemo, useState } from 'react'
import useActiveTemplate from '@/hooks/useActiveTemplate'
import { TemplateDetail, TemplateInputWidget } from '@/types'
import IconPlus from '@haiper/icons-svg/icons/outline/plus-large.svg'
import TemplateWidget from './widget'

export default function TemplateControls({ expanded, templateInputs, setTemplateInputs }: CreationInputControlsProps) {
  const { data: template } = useActiveTemplate()

  const inputs: TemplateInputWidget[] = useMemo(() => {
    // TODO: visible field is not present now.
    const result = ((template as TemplateDetail)?.inputs ?? []).filter((item) => item.visible !== false)

    // FIXME: temp solution
    if (template?.name === 'Logo Animation') {
      return result.filter((input) => input.key === 'input_image_url')
    }

    const validTypes = ['image_input', 'video_input', 'sam']
    return (result ?? [])?.filter((item) => validTypes.includes(item.widget))
  }, [template])

  const seperator = useMemo(
    () => (
      <div className='flex items-center justify-center p-4 shrink-0 last-of-type:hidden' aria-label='seperator'>
        <IconPlus className='size-5 text-icon-subdued shrink-0' />
      </div>
    ),
    [],
  )

  return (
    <fieldset
      className={cls(
        'self-start flex flex-col gap-1 p-3 pt-0 bg-surface w-full shrink-0 min-h-[110px]',
        expanded ? '' : 'hidden',
      )}
      aria-label='creation-input-fieldset'
    >
      <div className='w-full flex flex-col md:flex-row items-center justify-center'>
        {inputs.map((widget) => {
          return (
            <>
              <TemplateWidget
                inputWidget={widget}
                templateInputs={templateInputs!}
                setTemplateInputs={setTemplateInputs!}
              />
              {seperator}
            </>
          )
        })}
      </div>
      {/* {showTextarea && <div className='flex w-full flex-col'>{textarea}</div>} */}
    </fieldset>
  )
}
