import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import useCreationSettings from '@/hooks/useCreationSettings'
import { cls, whisper } from '@/utils'
import { Nullable } from '@/types'
import styles from './style.module.css'
import { DEFAULT_ASPECT_RATIO } from '@/constants'
import { useEffect } from 'react'

export interface AspectRatioOptionsProps {
  value: Nullable<string>
  onChange: (value: string) => void
}

export default function AspectRatioOptions({ value = DEFAULT_ASPECT_RATIO, onChange }: AspectRatioOptionsProps) {
  const realValue = value ?? DEFAULT_ASPECT_RATIO
  const { data: creationSettings } = useCreationSettings()

  useEffect(() => {
    if (!value) {
      onChange?.(DEFAULT_ASPECT_RATIO)
    }
  }, [value])

  return (
    <RadioGroup
      aria-label='Aspect Ratio'
      value={realValue}
      onValueChange={(value) => {
        onChange?.(value ?? DEFAULT_ASPECT_RATIO)
      }}
    >
      <div className='grid grid-cols-3 gap-2'>
        {creationSettings?.aspect_ratio_options?.map((ar) => {
          const id = `creation-input-aspect-ratio-option-${ar.value}`
          const active = ar.value === realValue
          return (
            <div
              key={id}
              className={cls(
                'h-21 w-21 border border-solid border-border rounded-md',
                active ? 'border-text-interactive bg-surface border-2' : 'bg-surface hover:bg-surface-hover p-px',
              )}
            >
              <label
                htmlFor={id}
                className={cls(
                  'box-border size-full flex flex-col gap-1 items-center cursor-pointer pt-[10px] pb-[6px]',
                )}
              >
                <div className='w-14 h-11 flex items-center justify-center' aria-label='aspect-ratio-shape'>
                  <img
                    src={ar.icon}
                    alt='Aspect Ratio Icon'
                    width={56}
                    height={44}
                    className={cls(
                      styles['aspect-ratio-icon'],
                      // active ? styles['active-aspect-ratio-icon'] : styles['aspect-ratio-icon'],
                    )}
                  />
                </div>
                <span className='text-text text-body-sm'>{String(ar.label?.replace(':', ' : '))}</span>
              </label>
              <RadioGroupItem id={id} value={ar.value} checked={realValue === ar.value} className='hidden' />
            </div>
          )
        })}
      </div>
    </RadioGroup>
  )
}
