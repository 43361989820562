import { useEffect } from 'react'
import { creationDetailsAtom } from '@/atoms'
import { useSetAtom } from 'jotai'

export default function useAutoCleanCreationDetails() {
  // clear all cache data
  const setCreationDetailsMap = useSetAtom(creationDetailsAtom)

  useEffect(() => {
    return () => {
      setCreationDetailsMap({})
    }
  }, [setCreationDetailsMap])
}
