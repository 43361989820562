import { useEffect, useMemo, useState } from 'react'
import useSamVary, { SamHooksParams } from '@/hooks/useSamVary'
import { cls, whisper } from '@/utils'
import Sam from '@/components/sam'
import IconButton from '@/components/icon-button'
import Button from '@/components/button'
import useAmplitude from '@/hooks/useAmplitude'
import ButtonVaryRegion, { ButtonVaryRegionProps } from '../button-vary-region'
import SamPrompt from '../sam-prompt'
import IconTrash from '@/public/assets/trash.svg'
import Loading from '@/components/loading'
import { useCachedSwitches } from '@/hooks/useSwitches'

interface SamWrapperProps extends SamHooksParams {
  className?: string
  thumbnailUrl?: string
  onDelete: () => void
  defaultVarying?: boolean
  containerClassName?: string
  varyRegionButtonProps?: Partial<ButtonVaryRegionProps>
  hidePrompt?: boolean
}

export function SamWrapper({
  className,
  fileId,
  inputType = 'video',
  url,
  thumbnailUrl,
  defaultVarying,
  hidePrompt = false,
  onChange,
  onDelete,
  varyRegionButtonProps,
  containerClassName,
}: SamWrapperProps) {
  const [varying, setVarying] = useState(defaultVarying ?? false)
  const { track } = useAmplitude()
  const { data: switches } = useCachedSwitches()
  const showSAM = !!switches?.sam

  const samVaryParams = useMemo(() => {
    return {
      fileId,
      url,
      inputType,
      onChange: varying ? onChange : undefined,
    }
  }, [varying, fileId, onChange, inputType, url])

  const { samProps, toolbarProps, loading, firstFrameLoading, error, retry } = useSamVary(samVaryParams)

  const hasError = Boolean(error)

  useEffect(() => {
    if (!defaultVarying) {
      setVarying(false)
    }
  }, [fileId, defaultVarying])

  useEffect(() => {
    if (varying) return
    whisper('onChange is called with undefined...')
    onChange?.(undefined)
  }, [varying, onChange])

  const realLoading = firstFrameLoading || loading
  whisper('firstFrameLoading: ', firstFrameLoading)
  whisper('loading: ', loading)
  whisper('realLoading', realLoading)
  whisper('hasError', hasError)
  whisper('varying', varying)

  useEffect(() => {
    track('click:creation:vary-region-usage-popup', { file_id: fileId })
  }, [track, fileId])

  return (
    <div className={cls('flex flex-col justify-center gap-3 md:gap-2 mx-auto w-full md:w-auto', className)}>
      <div
        className={cls(
          'bg-surface md:bg-white/5 relative rounded-lg overflow-hidden mx-auto size-full aspect-video flex items-center h-auto border border-border',
          varying && !realLoading ? 'md:w-[642px]' : 'md:w-[302px] bg-transparent',
          realLoading ? 'aspect-video h-auto md:h-auto' : '',
          containerClassName,
        )}
      >
        <Sam className={cls('size-full', varying && !realLoading && !hasError ? 'visible' : 'hidden')} {...samProps} />
        {/* loading */}
        <div
          className={cls(
            'w-full bg-surface backdrop-blur-[25px] flex flex-col items-center justify-center border border-border border-solid rounded-lg aspect-video',
            varying && (realLoading || hasError) ? 'visible' : 'hidden',
          )}
        >
          {/* loading */}
          <Loading className={cls('', hasError ? 'hidden' : 'visible')} />
          <div className={cls('text-body-md text-text-subdued mt-2', hasError ? 'hidden' : 'visible')}>
            Loading the region info
          </div>
          {/* error */}
          <div className={cls('text-body-md text-text-subdued mb-2', hasError ? 'visible' : 'hidden')}>
            Failed to load
          </div>
          <Button
            className={cls('rounded-lg text-text', hasError ? 'visible' : 'hidden')}
            type='button'
            variant='outline'
            onClick={(e) => {
              track('click:creation:vary-region-retry', {
                file_id: fileId,
                error: error?.message,
              })
              retry()
            }}
          >
            Retry
          </Button>
        </div>
        {thumbnailUrl ? (
          <img
            src={thumbnailUrl}
            className={cls(
              // 'size-full object-contain',
              'size-full md:h-auto max-h-full object-contain',
              varying ? 'hidden' : 'visible',
            )}
            alt=''
          />
        ) : null}
        <IconButton
          className={cls(
            'absolute rounded-sm p-1 shrink-0 border-none bg-transparent hover:bg-transparent active:bg-transparent hover:opacity-90 active:opacity-90',
            varying ? 'size-6 min-w-6 max-w-6' : 'size-4 min-w-4 max-w-4',
            'top-2 right-1',
          )}
          type='button'
          label='Remove video'
          side='bottom'
          sideOffset={4}
          onClick={() => {
            track('click:creation:vary-region-delete-file', {
              file_id: fileId,
            })
            onDelete()
          }}
        >
          <IconTrash className={cls('text-icon', varying ? 'size-6' : 'size-4')} />
        </IconButton>
      </div>
      <div className={cls('flex relative md:static justify-center', showSAM ? '' : 'invisible h-4')}>
        <ButtonVaryRegion
          {...varyRegionButtonProps}
          varying={varying}
          className={cls('', varying ? 'ml-0 md:ml-auto' : '', varyRegionButtonProps?.className)}
          toolbar={toolbarProps}
          disabled={realLoading}
          onClick={() => {
            setVarying(!varying)
            track('click:creation:vary-region-start', { file_id: fileId })
          }}
          onClose={() => {
            track('click:creation:vary-region-abort')
            toolbarProps.reset?.()
            setVarying(false)
          }}
        />
        {!hidePrompt && <SamPrompt triggerClassName={cls('md:bottom-4', varying ? 'relative md:absolute' : '')} />}
      </div>
    </div>
  )
}
