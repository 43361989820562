import { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { nanoid } from 'nanoid'

import { SamCallbackData, SamProps, SamToolbarProps } from '@/types'
import { InferenceSession, getModel } from '@/utils/onnx'

import useFirstFrameEmbedding from './useFirstFrameEmbedding'
import useEmbeddingTensor from './useEmbeddingTensor'
import useImageEmbedding from './useImageEmbedding'
import { DISABLE_SAM } from '@/constants'
import { whisper } from '@/utils'

export interface SamHooksParams {
  fileId: string
  url?: string
  inputType?: 'video' | 'image'
  onChange?: SamProps['onChange']
}

export interface SamHookResult {
  samProps: SamProps
  toolbarProps: SamToolbarProps
  clicks: any[]
  loading: boolean
  firstFrameLoading: boolean
  error: any
  retry: () => void
}

const useSamVary = ({ fileId, onChange, url, inputType }: SamHooksParams): SamHookResult => {
  const [mode, setMode] = useState<'add' | 'remove'>('add')
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [canReset, setCanReset] = useState(false)
  const [clicks, setClicks] = useState<any[]>([])
  const [undo, setUndo] = useState<VoidFunction | undefined>()
  const [redo, setRedo] = useState<VoidFunction | undefined>()
  const [reset, setReset] = useState<VoidFunction | undefined>()
  const modeSetterRef = useRef<((mode: 'add' | 'remove') => void) | undefined>()
  const modelName = 'vit_h'
  const [model, setModel] = useState<InferenceSession | null>(null)
  const [modelLoading, setModelLoading] = useState(true)

  useEffect(() => {
    const initModel = async () => {
      try {
        const model = await getModel(modelName)
        setModel(model)
        setModelLoading(false)
      } catch (e) {
        console.error(e)
      }
    }
    if (!DISABLE_SAM) {
      initModel()
    }
  }, [])

  const handleSetMode = useCallback((mode: 'add' | 'remove') => {
    modeSetterRef.current?.(mode)
  }, [])

  const [firstFrameKey, setFirstFrameKey] = useState(nanoid())
  const refetchFirstFrame = useCallback(() => {
    setFirstFrameKey(nanoid())
  }, [])

  const { data: firstFrameResult, isValidating: firstFrameDataLoading } = useFirstFrameEmbedding(
    !DISABLE_SAM && inputType === 'video' ? fileId : '',
    firstFrameKey,
  )

  const { data: imageEmbeddingRes, isValidating: imageEmbeddingResLoading } = useImageEmbedding(
    !DISABLE_SAM && inputType === 'image' ? url : '',
  )

  const firstFrameData = firstFrameResult?.data
  const firstFrameError = firstFrameResult?.error

  const imageUrl = useMemo(() => {
    whisper('inputType: ', inputType)
    if (inputType === 'video') {
      return firstFrameData?.first_frame_url ?? ''
    } else {
      return url ?? ''
    }
  }, [firstFrameData?.first_frame_url, url, inputType])

  const embeddingUrl = useMemo(() => {
    if (inputType === 'video') {
      return firstFrameData?.embedding_url ?? ''
    } else {
      return imageEmbeddingRes ?? ''
    }
  }, [firstFrameData?.embedding_url, imageEmbeddingRes, inputType])

  const [embeddingKey, setEmbeddingKey] = useState(nanoid())
  const refetchEmbedding = useCallback(() => {
    setEmbeddingKey(nanoid())
  }, [])
  const {
    data: embeddingTensor = null,
    isValidating: embeddingTensorLoading,
    error: embeddingError,
  } = useEmbeddingTensor(embeddingUrl, embeddingKey)

  const error = useMemo(() => {
    return firstFrameError || embeddingError
  }, [firstFrameError, embeddingError])

  const retry = useCallback(() => {
    if (firstFrameError) {
      refetchFirstFrame()
      return
    }
    if (embeddingError) {
      refetchEmbedding()
      return
    }
  }, [firstFrameError, embeddingError, refetchEmbedding, refetchFirstFrame])

  const hasEmptyInput = !imageUrl || !embeddingUrl || !model || !embeddingTensor
  whisper('imageUrl: ', imageUrl)
  whisper('embeddingUrl: ', embeddingUrl)
  whisper('model: ', model)
  whisper('embeddingTensor: ', embeddingTensor)

  const firstFrameLoading = inputType === 'video' ? firstFrameDataLoading : imageEmbeddingResLoading

  const loading = firstFrameLoading || modelLoading || embeddingTensorLoading || hasEmptyInput
  whisper('firstFrameLoading(inside): ', firstFrameLoading)
  whisper('modelLoading: ', modelLoading)
  whisper('embeddingTensorLoading: ', embeddingTensorLoading)
  whisper('hasEmptyInput: ', hasEmptyInput)

  const toolbarProps: SamToolbarProps = useMemo(() => {
    return {
      mode,
      setMode: handleSetMode,
      canUndo,
      canRedo,
      canReset,
      undo,
      redo,
      reset,
    }
  }, [mode, canUndo, canRedo, canReset, undo, redo, reset, handleSetMode])

  const handleSamStateChange: SamProps['onChange'] = useCallback(
    (data?: SamCallbackData | undefined) => {
      whisper('handleSamStateChange..., data is: ', data)

      setClicks(data?.clicks ?? [])
      setCanUndo(!!data?.canUndo)
      setCanRedo(!!data?.canRedo)
      setCanReset(!!data?.canReset)
      setUndo(() => data?.undo ?? null)
      setRedo(() => data?.redo ?? null)
      setReset(() => data?.reset ?? null)
      setMode(data?.mode ?? 'add')
      modeSetterRef.current = data?.setMode

      onChange?.(
        data
          ? {
              converted_video: firstFrameData?.converted_video ?? undefined,
              ...data,
            }
          : undefined,
      )
    },
    [onChange, firstFrameData?.converted_video],
  )

  const samProps: SamProps = useMemo(() => {
    return {
      onChange: handleSamStateChange,
      imageUrl,
      embeddingUrl,
      modelName,
      model,
      embeddingTensor: embeddingTensor ?? null,
    }
  }, [handleSamStateChange, embeddingTensor, imageUrl, embeddingUrl, modelName, model])

  return {
    loading,
    firstFrameLoading,
    samProps,
    toolbarProps,
    clicks,
    error,
    retry,
  }
}

useSamVary.whyDidYouRender = true

export default useSamVary
