import { cls, fullTextSearch, whisper } from '@/utils'
import Dialog, { DialogProps } from '@/components/dialog'
import { useCallback, useMemo, useState } from 'react'
import { PoNVoid } from '@/types'
import Button from '@/components/button'
import Video from '@/components/video'
import IconSearch from '@haiper/icons-svg/icons/outline/search.svg'
import Input from '@/components/input'

export interface UploadSample {
  name: string
  url: string
  thumbnail_url?: string
}

export interface UploadSampleDialogProps extends DialogProps {
  showSearch?: boolean
  fileType: 'video' | 'image'
  samples: UploadSample[]
  onPickSample?: (sample: UploadSample) => PoNVoid
}

export default function UploadSampleDialog({
  fileType,
  samples,
  title,
  className,
  onPickSample,
  showSearch,
  ...props
}: UploadSampleDialogProps) {
  const [selectedSample, setSelectedSample] = useState<UploadSample | null>(null)
  const [keyword, setKeyword] = useState('')

  const realTitle = useMemo(() => {
    if (typeof title === 'string') {
      return <div className='w-full flex items-center justify-center'>{title}</div>
    }
    return title
  }, [title])

  const handleOk = useCallback(async () => {
    await onPickSample?.(selectedSample!)
  }, [selectedSample, onPickSample])

  const filteredSamples = useMemo(() => {
    return samples.filter((sample) => fullTextSearch(sample.name, keyword))
  }, [samples, keyword])

  return (
    <Dialog
      title={realTitle}
      className={cls('md:w-[800px] p-6 pb-0', className)}
      footer={
        <div className='flex justify-center w-full items-center bg-surface py-4'>
          <Button variant='primary' className='w-19' onClick={handleOk}>
            Confirm
          </Button>
        </div>
      }
      {...props}
    >
      <div className='flex flex-col gap-4 my-4 h-[448px]' aria-label=''>
        {showSearch && (
          <div
            className='w-full flex items-center border border-border px-3 py-2 rounded-md gap-2 h-10'
            aria-label='search-bar'
          >
            <IconSearch className='size-6 text-icon-subdued' />
            <Input
              type='text'
              placeholder='Search'
              className='flex-1 bg-transparent outline-none border-0 border-none'
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>
        )}
        <div className='flex flex-col gap-4 overflow-y-auto no-scrollbar flex-1'>
          <div className='grid grid-cols-1 tablet:grid-cols-2 md:grid-cols-3 gap-4'>
            {filteredSamples.map((sample, index) => {
              const isSelected = selectedSample === sample
              whisper('sample is: ', sample)
              return (
                <div
                  key={[sample.name, sample.url, index].join()}
                  className={cls(
                    'flex flex-col p-2 gap-2 border-2 border-b-4 rounded-xl',
                    isSelected ? 'border-border-active' : 'border-border',
                  )}
                >
                  {fileType === 'video' ? (
                    <Video
                      playOnHover
                      loop
                      playsInline
                      controls={false}
                      src={sample.url}
                      poster={sample?.thumbnail_url ?? undefined}
                      className='w-full aspect-video object-scale-down rounded-md'
                      onClick={() => setSelectedSample(sample)}
                    />
                  ) : (
                    <img
                      src={sample.url}
                      alt={sample.name}
                      className='w-full aspect-video object-scale-down rounded-md'
                      onClick={() => setSelectedSample(sample)}
                    />
                  )}
                  <span className='text-body-md tracking-15'>{sample.name}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Dialog>
  )
}
