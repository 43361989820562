import Button from '@/components/button'
import { TemplateInputWidget, InnerUploadFile } from '@/types'
import { Dispatch, SetStateAction, useCallback, useState, useMemo } from 'react'
import { cls, getImageResolution, getVideoResolution, whisper } from '@/utils'
import Upload from '@/components/upload'
import IconAIStar from '@haiper/icons-svg/icons/outline/ai-star.svg'
import { Accept } from 'react-dropzone'
import IconInfo from '@haiper/icons-svg/icons/outline/info-circle.svg'
import Tooltip from '@/components/tooltip'
import UploadSampleDialog, { UploadSample } from '@/components/gs-upload/sample-dialog'
import { ALLOWED_IMAGE_EXTENSIONS, ALLOWED_VIDEO_EXTENSIONS } from '@/constants'
import SamInput from '@/components/sam-input'
import { useCachedUploadSamples } from '@/hooks/useUploadSamples'

export interface TemplateWidgetProps {
  inputWidget: TemplateInputWidget
  templateInputs: Record<string, any>
  setTemplateInputs: Dispatch<SetStateAction<Record<string, any>>>
}

// const MAX_DURATION = 16
const MAX_VIDEO_FILE_SIZE = 100 * 1024 * 1024 // 100MB
const MAX_IMAGE_FILE_SIZE = 5 * 1024 * 1024 // 5MB

export default function TemplateWidget({ inputWidget, templateInputs, setTemplateInputs }: TemplateWidgetProps) {
  const { key, name, default_value, type, widget, samples } = inputWidget ?? {}
  const { data: uploadSamples } = useCachedUploadSamples()

  const realSamples = useMemo(() => {
    if (samples) {
      return samples
    }
    return (widget === 'image_input' || widget === 'sam' ? uploadSamples?.face_image : uploadSamples?.face_video) ?? []
  }, [samples, uploadSamples, widget])

  whisper('realSamples is: ', realSamples)

  const [showSampleDialog, setShowSampleDialog] = useState(false)

  const imageEmptyText = useMemo(() => {
    return (
      <div className='flex flex-col w-full text-body-md font-medium tracking-15'>
        <span>JPEG, PNG, WebP</span>
        <span>≤ 5MB</span>
      </div>
    )
  }, [])

  const videoEmptyText = useMemo(() => {
    return (
      <div className='flex flex-col w-full text-body-md font-medium tracking-15'>
        <span>MP4, MKV, MOV</span>
        <span>≤ 100MB, ≤ 16s</span>
      </div>
    )
  }, [])

  const handleValueChange = useCallback(
    (value: any) => {
      setTemplateInputs?.((prev) => ({ ...prev, [key]: value }))
    },
    [key, setTemplateInputs],
  )

  const buildFileFromSample = useCallback(async (sample: UploadSample, inputType: 'image' | 'video') => {
    const { width, height } =
      inputType === 'image' ? await getImageResolution(sample.url) : await getVideoResolution(sample.url)

    // const thumbnailUrl = inputType === 'image' ? sample.url : (await generateVideoThumbnailFromUrl(sample.url ?? '')).dataUrl
    const thumbnailUrl: string = sample?.thumbnail_url ?? (inputType === 'image' ? sample.url : '')

    const file: InnerUploadFile = {
      url: sample.url,
      thumbnailUrl,
      fileId: '',
      thumbnailFileId: '',
      width,
      height,
      status: 'done',
    }
    return file
  }, [])

  const sampleButton = (
    <Button
      variant='outline'
      className='w-full h-8 shrink-0 rounded-md'
      disabled={!realSamples?.length}
      onClick={() => setShowSampleDialog(true)}
    >
      <div className='flex items-center gap-1'>
        <IconAIStar className='size-4 text-icon-interactive' />
        <span>Ideas</span>
      </div>
    </Button>
  )

  if (widget === 'image_input' || widget === 'video_input' || widget === 'sam') {
    const fileType = widget === 'image_input' || widget === 'sam' ? 'image' : 'video'

    // const UploadComp = type === 'gcs_key' ? GSUpload : Upload
    const UploadComp = Upload
    const accept: Accept =
      fileType === 'image'
        ? {
            'image/png': ALLOWED_IMAGE_EXTENSIONS,
            'image/jpeg': ALLOWED_IMAGE_EXTENSIONS,
            'image/webp': ALLOWED_IMAGE_EXTENSIONS,
          }
        : {
            // video mimetypes, mp4, mkv, mov
            'video/mp4': ALLOWED_VIDEO_EXTENSIONS,
            // 'video/mkv': ALLOWED_VIDEO_EXTENSIONS_IN_TEMPLATE,
            'video/x-matroska': ALLOWED_VIDEO_EXTENSIONS,
            'video/quicktime': ALLOWED_VIDEO_EXTENSIONS,
          }
    return (
      <div className='flex flex-col items-center gap-2'>
        <div className='relative w-full h-5 flex justify-center items-center'>
          <span className='text-body-md font-medium tracking-15'>{name}</span>
          {(widget === 'image_input' || widget === 'video_input') && (
            <Tooltip
              asChild
              className='bg-surface-on-video border-border text-text-on-color'
              trigger={
                <Button
                  variant='transparent'
                  className='absolute right-0 grow-0 shrink-0 p-0 h-5 max-w-5 hover:bg-transparent active:bg-transparent'
                >
                  <IconInfo className='size-5 text-icon-subdued hover:opacity-80' />
                </Button>
              }
            >
              {fileType === 'image' ? imageEmptyText : videoEmptyText}
            </Tooltip>
          )}
        </div>
        {widget === 'image_input' || widget === 'video_input' ? (
          <UploadComp
            key={key}
            accept={accept}
            // maxDuration={MAX_DURATION}
            maxFileSize={widget === 'image_input' ? MAX_IMAGE_FILE_SIZE : MAX_VIDEO_FILE_SIZE}
            file={templateInputs?.[key] ?? null}
            fileType={widget === 'image_input' ? 'image' : 'video'}
            // emptyText={widget === 'image_input' ? imageEmptyText : videoEmptyText}
            className={cls('border')}
            onChange={handleValueChange}
          />
        ) : (
          <SamInput
            fileType={fileType}
            tooltips={['Upload Image', 'Click to select']}
            value={templateInputs?.[key]}
            onChange={handleValueChange}
          />
        )}
        {sampleButton}
        <UploadSampleDialog
          showSearch
          fileType={fileType === 'image' ? 'image' : 'video'}
          open={showSampleDialog}
          title={`Ideas - ${name}`}
          samples={realSamples}
          onOpenChange={() => setShowSampleDialog(false)}
          onCancel={() => setShowSampleDialog(false)}
          onPickSample={async (sample) => {
            const file = await buildFileFromSample(sample, fileType === 'image' ? 'image' : 'video')
            if (inputWidget.widget === 'sam') {
              setTemplateInputs?.((prev) => ({ ...prev, [key]: { file } }))
            } else {
              setTemplateInputs?.((prev) => ({ ...prev, [key]: file }))
            }
            setShowSampleDialog(false)
          }}
        />
      </div>
    )
  }
  return null
}
