import IconEyeOff from '@haiper/icons-svg/icons/outline/eye-off.svg'
import IconEye from '@haiper/icons-svg/icons/outline/eye.svg'
import { Switch } from '@lemonsqueezy/wedges'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import Button from '@/components/button'
import UpgradeButton from '@/components/upgrade-button'
import { cls } from '@/utils'
import useActivePlan from '@/hooks/useActivePlan'
import { useAtom } from 'jotai'
import { creationInputPrivateModeAtom } from '@/atoms'

export interface VisibilitySettingProps {
  className?: string
}

export default function VisibilitySetting({
  className,
}: VisibilitySettingProps) {
  const [creationInputPrivateMode, setCreationInputPrivateMode] = useAtom(
    creationInputPrivateModeAtom,
  )

  const Icon = creationInputPrivateMode ? IconEyeOff : IconEye
  const { data: plan } = useActivePlan()
  const canGeneratePrivateVideo = !!plan?.allow_private_generation

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant='outline'
          aria-label='Visibility Setting'
          type='button'
          tooltip={
            creationInputPrivateMode ? 'Create in Private' : 'Create in Public'
          }
          className={cls(
            'my-0 p-0 shrink-0 w-8 h-8 rounded-md inline-flex items-center justify-center text-icon-subdued',
            className,
          )}
        >
          <Icon className='text-icon size-5' />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        collisionPadding={16}
        className='w-[300px] flex justify-between border border-solid border-border rounded-lg bg-surface backdrop-blur-xl p-4'
        data-component='creation-input'
        data-outside='false'
      >
        <div className='flex flex-col gap-4'>
          <div className='flex w-full'>
            <div className='flex flex-col gap-1' aria-label='left'>
              <div className='text-body-lg text-text'>Create in Private</div>
              <div className='text-body-md text-text-subdued flex flex-col gap-5'>
                <span>
                  Create in private, making the videos you create only
                  accessible to yourself.
                </span>
                {!canGeneratePrivateVideo && (
                  <span>Upgrade to access the feature.</span>
                )}
              </div>
            </div>
            <div className='flex items-start ml-auto' aria-label='right'>
              <Switch
                disabled={!canGeneratePrivateVideo}
                className={cls(
                  '',
                  !canGeneratePrivateVideo ? 'cursor-not-allowed' : '',
                )}
                checked={creationInputPrivateMode}
                onCheckedChange={(checked) => {
                  setCreationInputPrivateMode?.(checked)
                }}
              />
            </div>
          </div>
          {!canGeneratePrivateVideo && (
            <UpgradeButton className='w-full h-10' source='visibility' />
          )}
        </div>
      </PopoverContent>
    </Popover>
  )
}
